import type { ItemModel } from './types.ts';
import type DataObject from './DataObject.ts';
import type { DataGridControl } from 'o365-datagrid';
import type { Procedure } from 'o365-modules';
import type { FilterItem } from 'o365-filterobject';

import LayoutStore from './DataObject.Layout.LayoutsStore2.ts';
import { getOrCreateProcedure, userSession, app, configurableRegister } from 'o365-modules';
import { $t } from 'o365-utils';
import { getDataObjectById } from './store.ts'

/** Symbol to ensure only this module script is executing certain functions */
const layoutsModuleSymbol = Symbol('LayoutsModuleSymbol');

/** Layouts manager control for data objects */
export default class LayoutManager<T extends ItemModel = ItemModel> {
    private _dataObjectId: string;
    private _appId: string;
    /** Indicates that the dataobject comes from site scope */
    private _isSite = false;
    /** Original instance form the DataObject constructor */
    private _rawDataObject: DataObject<T>;
    /** Indicates that layout manager has finished construction and that getDataObjectById is now available to use */
    private _constructed = false;
    /** Indicates that this instance is marked as destroyed and it should stop its functions */
    private _destroyed = false;
    get dataObjectId() { return this._dataObjectId; }
    /** Class definitions for registered layout modules */
    private _registeredModules: Record<string, LayoutModuleConstructor<T>> = {};
    /** Options for individual modules */
    private _registeredModulesOptions: Record<string, any> = {};
    /** Active layout object. Once created should not be removed */
    private _activeLayout: LayoutObject<T> | null = null;
    /** Baseline layout values set upon initial module registration. Used when resetting layouts */
    private _baselineLayout: Record<string, any> = {};
    /** Control for retrieving and storing layouts from/to local storage */
    private _layoutStore: LayoutStore | null = null;
    /** Indicator for if layouts for the app have been validated */
    private _layoutsValidated = false;
    /** Indicator for if the current applied layout is outdated */
    private _activeLayoutOutdated = false;
    /** Override value to disable layout saving */
    // private _pauseSaving = false;
    get _pauseSaving() { return false; }
    set _pauseSaving(_pVal) {
        // TODO: Remove references to autosave pausing
        // Layout autosave removed.
    }
    /** User controlled layout save lock */
    // private _lockLayout = false;
    get _lockLayout() { return false; }
    set _lockLayout(_pVal) {
        // TODO: Remove references to layout locking
        // Layout locking removed since layouts are no longer autosaved.
    }
    /** Indicates that an layout is being saved */
    private _isSaving = false;
    /** indicates that shared layout is in edit mode */
    private _inEditMode = false;
    /** Procedure for saving and retrieving layouts */
    private _manageProc: Procedure<{
        DataObject_ID: string,
        Action: 'put' | 'retrieve' | 'delete' | 'share' | 'unshare' | 'delete_default' | 'set_default' | 'set_mew_default', //TODO: Fix 'mew' typo (in procedure as well)
        App_ID: string,
        Layout?: string,
        Layout_ID?: number,
        Name?: string,
        Description?: string,
        Person_ID?: number,
        OrgUnit_ID?: number,
        Register_ID?: number,
        Default?: boolean
    }>;
    /** The current active layout object */
    get activeLayout() {
        if (this._activeLayout == null) {
            this._activeLayout = this._getEmptyLayoutObject();
        }
        return this._activeLayout;
    }
    /** Indicator for if there's an applied layout */
    get hasActiveLayout() {
        return !!this._activeLayout;
    }
    private get _localStoreKey() {
        return configurableRegister.isConfigured && configurableRegister.id
            ? `${this._appId}_${this.dataObjectId}_${configurableRegister.id}_layout`
            : `${this._appId}_${this.dataObjectId}_layout`
    }

    /** Get the current stored layout value json */
    private get _currentStoredLayoutJson() {
        try {
            if (!this.hasActiveLayout) { return null; }
            const layout = this.activeLayout.record.Layout;
            if (layout) {
                return JSON.parse(layout);
            } else {
                return null;
            }
        } catch (ex) {
            console.warn(ex);
            return null;
        }
    }

    /** Get the current stored layout changes (unsaved layout) */
    private get _currentStoredChangesJson() {
        try {
            if (!this.hasActiveLayout) { return null; }
            const changes = this._layoutStore?.getStoredChanges()?.Layout;
            if (changes) {
                return JSON.parse(changes);
            } else {
                return null;
            }
        } catch (ex) {
            console.warn(ex);
            return null;
        }
    }

    /** Return true when shared layout is in edit mode */
    get inEditMode() {
        return this._inEditMode;
    }

    /** Enter/exit edit mode for shared context layouts */
    enterEditMode(pValue: boolean) {
        if (pValue) {
            this.reapplyLayout(false, true);
        }
        this._inEditMode = pValue;
        this._pauseSaving = !pValue;
    }

    /** Returns true if the layout can be saved. Depends on the current state of the layout manager */
    get canSave() {
        return this._layoutsValidated && !this._activeLayoutOutdated && !this._pauseSaving && !this._isSaving && !this.lockLayout;
    }

    /** Returns true if the layout can be currently saved as new */
    get canSaveAs() {
        return this._layoutsValidated && this._activeLayout && !this._activeLayoutOutdated && !this._isSaving && !this.lockLayout;
    }

    /** Indicator for if layouts have finished validation process */
    get layoutsValidated() {
        return this._layoutsValidated;
    }

    /** Incidator if the current active layout is valid */
    get activeLayoutValid() {
        return !this._activeLayoutOutdated;
    }

    /** Indicates that a layout is currently being saved */
    get isSaving() {
        return this._isSaving;
    }

    /** User controlled layout saving lock */
    get lockLayout() { return this._lockLayout; }
    set lockLayout(pValue) {
        this._lockLayout = pValue;
        this._layoutStore?.setIsLocked(this._activeLayout?.id, pValue);
        if (!pValue) {
            this.reapplyLayout(true);
        }
    }

    /** Array of registered layout modules */
    get registeredModules() { return Object.keys(this._registeredModules); }

    constructor(pDataObject: DataObject<T>) {
        this._dataObjectId = pDataObject.id;
        if (pDataObject.appId === 'site') {
            this._isSite = true;
            this._appId = app.id;
        } else {
            this._appId = pDataObject.appId;
        }
        this._rawDataObject = pDataObject;

        this._manageProc = getOrCreateProcedure({
            id: `procManageLayouts_${this._dataObjectId}`,
            procedureName: 'sstp_O365_ManageLayouts'
        }, false);

        this.initialize();
        this._constructed = true;
    }

    /** Run initialization processes */
    initialize() {
        try {
            this._layoutStore = new LayoutStore(this._localStoreKey);
            const currentLayout = this._layoutStore.getCurrentLayout();
            const layoutChanges = this._layoutStore.getStoredChanges();
            if (currentLayout) {
                this._activeLayout = this._createLayoutObjectFromRecord(currentLayout);
                if (layoutChanges && this._activeLayout.id == layoutChanges.ID) {
                    // Apply changes
                }
            } else if (layoutChanges) {
                this._activeLayout = this._getEmptyLayoutObject();
                // this._activeLayout = this._createLayoutObjectFromRecord(layoutChanges);
            }

            this._validateLayouts();
        } catch (ex) {
            console.error(ex);
            throw new LayoutError('An error has occured when retrieving layouts from local storage', this);
        }
    }

    /** 
     * Register a layout module on the layout manager
     * @param {string} pKey Identifier for the layout module
     * @param {LayoutModule} pLayoutModule Class definition of the module
     */
    registerModule<LMCT extends LayoutModuleConstructor<T>, MOT = any>(pKey: string, pLayoutModule: LMCT, pModuleOptions?: MOT) {
        if (this._registeredModules[pKey]) {
            if (this._activeLayout) {
                this.activeLayout._removeModule(pKey, layoutsModuleSymbol);
            }
        } else {
            this._registeredModulesOptions[pKey] = pModuleOptions;
        }

        if (this._activeLayout) {
            const layoutModule = new pLayoutModule(this._getModuleOptions(pKey), this._currentStoredLayoutJson?.[pKey], pModuleOptions, this._activeLayout.parentLayout?.[pKey], this._currentStoredChangesJson?.[pKey]);
            this._activeLayout.appendModule(layoutModule);
        }

        // this._baselineLayout[pKey] = pBaseline;

        this._registeredModules[pKey] = pLayoutModule;
    }

    /**
     * Update the baseline value for a layout module
     * @param {string} pKey Identifier of the layout module
     * @param {any} pBasseline New baseline value
     */
    updateBaseline<T>(pKey: string, pBasseline: T) {
        this._baselineLayout[pKey] = pBasseline;
    }

    /**
     * Apply a layout record to the layout object
     * @param {ILayoutRecord} pLayout The layout record to apply, if not provided will reset instead
     */
    async applyLayout(pLayout: ILayoutRecord, pUserSet?: boolean) {
        this._layoutStore?.deleteLayoutChanges();
        this._inEditMode = false;
        await this._confirmLayoutUnlock(pLayout.ID);
        // if (this._lockLayout) {
        //     // this._layoutStore?.setIsLocked(this._false);
        // }
        // this._lockLayout = false;
        if (pLayout == null) {
            return this.resetLayout();
        }

        if ((pLayout.OrgUnit_ID != null && !pLayout.Default) || (pLayout.Person_ID != null && pLayout.Person_ID !== userSession.personId)) {
            this._pauseSaving = true;
        } else {
            this._pauseSaving = false;
        }

        // if (pLayout.OrgUnit_ID && pLayout.Default) {
        //     this._layoutStore?.removeUserSetLayout();
        // }

        if (this._activeLayout == null) {
            this._activeLayout = this._createLayoutObjectFromRecord(pLayout);
        } else {
            this.activeLayout._updateRecord(pLayout, layoutsModuleSymbol);
            let layoutValues: Record<string, any> = {};
            try {
                layoutValues = JSON.parse(pLayout.Layout);
            } catch (_ex) {
                layoutValues = {}
            }
            this.activeLayout.modulesArray.forEach(layoutModule => {
                const moduleValue = layoutValues[layoutModule.key];
                layoutModule.apply(moduleValue);
            });
        }

        this._layoutStore?.storeLayout(pLayout, pUserSet);
        const reloadDataObject = Object.keys(this._activeLayout.layout).some(key => this._activeLayout!.modules[key].shouldLoadDataObject());
        if (reloadDataObject) {
            const beforeLoadPromises = this._activeLayout.modulesArray.filter(x => x.beforeLoadPromise).map(x => x.beforeLoadPromise);
            if (beforeLoadPromises.length > 0) {
                await Promise.all(beforeLoadPromises);
            }
            this._activeLayout.modulesArray.forEach(x => x.beforeLoadPromise = undefined);
            const dataObject = this._getDataObject();
            if (dataObject?.filterObject.hasChanges) {
                dataObject?.filterObject.apply();
            } else {
                dataObject?.load()
            }
        }

        this._getDataObject()?.emit('LayoutApplied');
    }

    /**
     * Creates a new empty layout and sets it as active
     */
    async createNewLayout(pOptions: {
        name: string
    }) {
        this.unsetLayout();
        this._getEmptyLayoutObject();
        this._activeLayout = this._getEmptyLayoutObject({
            Default: false
        });
        await this.saveLayout({
            name: pOptions.name,
            saveAsNew: true,
            skipChecks: true
        })
    }

    /** 
     * Reapply the current layout from local storage.
     * Can be called only after stored layouts are validated.
     * @param {boolean} pResetBeforeApply Reset registered modules before applying the layout
     */
    reapplyLayout(pResetBeforeApply?: boolean, pSkipLayoutResolve?: boolean) {
        if (!this.layoutsValidated) { return; }
        let layout = undefined;
        if (pSkipLayoutResolve) {
            layout = this._layoutStore?.getLayoutById(this._activeLayout?.id!)
        } else {
            layout = this._layoutStore?.getCurrentLayout();
        }

        if (layout == null) {
            if (this._activeLayout) {
                this.resetLayout();
            }
        } else {
            if (pResetBeforeApply && this._activeLayout) {
                this._activeLayout.modulesArray.forEach(layoutModule => {
                    layoutModule.reset();
                });
            }
            this.applyLayout(layout);
        }

        this._activeLayoutOutdated = false;
    }

    /** 
     * Check for layout changes and attempt to save 
     * @param {object} pOptions Optional options
     */
    async saveLayout(pOptions: {
        /** When provided will only update the modules included in this array */
        includedModules?: string[],
        /** When enabled will save the layout with included modules, non included ones will be removed from the layout */
        removeNonIncluded?: boolean,
        /** Will create a copy of the layout */
        saveAsNew?: boolean
        /** Name for the layout when saving as new copy */
        name?: string,
        /** Skip all checks and send a save request */
        skipChecks?: boolean,
        /** An override for 'save as new'. Instead of copying the record will save the current layout changes */
        newWithChanges?: boolean,
    } = {}) {
        if (this._activeLayout == null) { return; }


        if (!this.canSave || pOptions.saveAsNew) {
            if (pOptions.saveAsNew) {
                if (!pOptions.skipChecks && (!this.layoutsValidated || !this.activeLayoutValid)) { return; }
                this.activeLayout.setAsNew();
            } else {
                return;
            }
        }
        if (this._activeLayout.isDefault) {
            const canSaveDefault = await this.canSetLayoutAsDefault();
            if (!canSaveDefault) { return; }
        }
        try {
            this._isSaving = true;
            // Check for changes in to be saved modules
            if (!pOptions.saveAsNew) {
                if (pOptions.includedModules) {
                    // Saving only specific modules
                    const inlcudedHaveChanges = pOptions.includedModules.some(key => {
                        return this.activeLayout.modules[key]?.hasChanges();
                    })
                    if (!inlcudedHaveChanges) { this._isSaving = false; return; }
                } else {
                    // Saving all modules
                    if (!this.activeLayout.hasChanges()) { this._isSaving = false; return; }
                }
            }

            // Get the new layout to be saved
            let layout: Record<string, any> = {};
            if (pOptions.includedModules) {
                pOptions.includedModules.forEach(key => layout[key] = this._activeLayout!.modules[key]?.getValueForSave())
                if (!pOptions.removeNonIncluded) {
                    // Add back non-included module saved values if removeNonIncluded option is not specified
                    const currentLayoutJson = this.activeLayout.layoutRecord;
                    Object.keys(currentLayoutJson).forEach(key => {
                        if ((pOptions.saveAsNew && !pOptions.newWithChanges) || !pOptions.includedModules?.includes(key)) {
                            layout[key] = currentLayoutJson[key];
                        }
                    });
                }
            } else {
                // Get the entire current layout
                layout = (pOptions.saveAsNew && !pOptions.newWithChanges) ? this.activeLayout.layoutRecord : this.activeLayout.layoutToSave;
            }

            // Remove empty modules
            Object.keys(layout).forEach(key => {
                if (layout[key] === undefined) {
                    delete layout[key];
                }
            });

            // if (!this._inEditMode) {
            //     switch (this.activeLayout.layoutType) {
            //         case LayoutType.Shared:
            //         case LayoutType.SharedDefault:
            //             this.activeLayout.setAsNew();
            //             break;
            //     }
            // }

            if (pOptions.saveAsNew && this.activeLayout.parentLayout != null) {
                this._activeLayout.modulesArray.forEach(layoutModule => {
                    const key = layoutModule.key;
                    const mergedValue = layoutModule.mergeValues(layout[key], this._activeLayout!.parentLayout![key]);
                    if (mergedValue) {
                        layout[key] = mergedValue;
                    }
                });
            }

            if (Object.keys(layout).length > 0 || pOptions.skipChecks) {
                const layoutRecord = {
                    App_ID: this._appId,
                    DataObject_ID: this._dataObjectId,
                    OrgUnit_ID: this.activeLayout.contextId,
                    Register_ID: this.activeLayout.registerId ?? configurableRegister.id,
                    Layout: JSON.stringify(layout),
                    Name: (pOptions.saveAsNew ? pOptions.name : this.activeLayout.name) ?? userSession.name!,
                    Description: this.activeLayout.description,
                    Person_ID: this.activeLayout.personId,
                    Layout_ID: this.activeLayout.id,
                    Default: this.activeLayout.isDefault,
                };
                const response: {
                    Table: {
                        Layout_ID: number,
                        Updated: string
                    }[],
                } = await this._manageProc.execute({
                    Action: 'put',
                    ...layoutRecord
                });

                this.activeLayout.modulesArray.forEach(layoutModule => {
                    // if (layout[layoutModule.key]) {
                    layoutModule.updateValue(layout[layoutModule.key]);
                    // }
                });

                this.activeLayout.id = response.Table[0].Layout_ID;
                this.activeLayout.updated = response.Table[0].Updated;
                this.activeLayout.name = layoutRecord.Name;
                this.activeLayout.record.Layout = layoutRecord.Layout;

                this._layoutStore?.storeLayout({
                    ...layoutRecord,
                    ID: response.Table[0].Layout_ID,
                    Updated: response.Table[0].Updated
                });
                // Send update or create request
            } else {
                this.resetLayout();
            }

        } catch (ex) {
            this._isSaving = false;
            throw this._wrapLayoutException(ex);
        } finally {
            this._isSaving = false;
        }
    }

    /**
     * Store current changes to local storage
     */
    saveLocalChanges() {
        if (this._activeLayout == null) {
            this._layoutStore?.deleteLayoutChanges();
            return;
        }

        if (!this._activeLayout.hasChanges()) {
            const storedChanges = this._currentStoredChangesJson;
            if (!storedChanges || Object.keys(storedChanges).every(layoutModule => !!this._activeLayout!.modules[layoutModule])) {
                this._layoutStore?.deleteLayoutChanges();
            } 
            return;
        }

        const layoutRecord = {
            ID: this._activeLayout.id,
            Updated: this._activeLayout.updated,
            App_ID: this._appId,
            DataObject_ID: this._dataObjectId,
            OrgUnit_ID: this.activeLayout.contextId,
            Register_ID: this.activeLayout.registerId ?? configurableRegister.id,
            Layout: JSON.stringify(this._activeLayout.layoutToSave),
            Name: this.activeLayout.name ?? '',
            Description: this.activeLayout.description,
            Person_ID: this.activeLayout.personId,
            Layout_ID: this.activeLayout.id,
            Default: this.activeLayout.isDefault,
        };

        this._layoutStore?.storeLayoutChanges(layoutRecord);
    }

    /**
     * Unset active layout and update registered modules to baseline values
     */
    unsetLayout() {
        this._inEditMode = false;
        this._lockLayout = false;
        if (this._activeLayout) {
            this.activeLayout.modulesArray.forEach(layoutModule => {
                layoutModule.reset();
            });
            this._layoutStore?.deleteLayout(this._activeLayout.record);
            this._activeLayout = null;
        }
    }

    /** 
     * Reset the current layout. Behaviour depends on the currently applied layout's type:  
     * - When `Personal Default` is applied this will delete it and apply the next closet one (either context or baseline)
     * - When `Personal` is applied this will unset it and apply the next closest one (either personal default, context personal default, context default or baseline)
     * - When `Context Default` is applied this will delete it and apply the next closest one (either personal default or baseline)
     * - When `Context Personal Default` is applied this appwill delete it and apply the next closest one (context)
     * - When `Shared` is applied this will unset it and apply the next closest one (either context personal default, context default, personal default or baseline)
     */
    resetLayout() {
        this._inEditMode = false;
        this._lockLayout = false;
        if (!this._layoutsValidated) { return; }
        if (this._activeLayout) {
            if (this._activeLayout.layoutType === LayoutType.PersonalDefault || this._activeLayout.layoutType === LayoutType.SharedPersonalDefault) {
                this._manageProc.execute({
                    Action: 'delete',
                    DataObject_ID: this.dataObjectId,
                    App_ID: this._appId,
                    Register_ID: configurableRegister.id,
                    Layout_ID: this._activeLayout.id,
                })
            }
            // this._layoutStore?.deleteLayout(this.activeLayout.record);
            this._layoutStore?.deleteUserSetLayout();
            this._layoutStore?.deleteLayoutChanges();
            const currentLayout = this._layoutStore?.getCurrentLayout();
            if (currentLayout) {
                this.applyLayout(currentLayout);
                // Found a layout to apply, apply it
            } else {
                // No layout found, reset to baseline
                this.activeLayout._updateRecord({
                    App_ID: this._appId,
                    Register_ID: configurableRegister.id,
                    Person_ID: userSession.personId,
                    Default: true
                }, layoutsModuleSymbol);
                this.activeLayout.modulesArray.forEach(layoutModule => {
                    layoutModule.reset();
                });
            }
        }
        this._pauseSaving = false;
        this._getDataObject()?.emit('LayoutApplied');
    }

    /**
     * Delete default layout for org unit. This will also remove all dependant 
     * personal context override layouts.
     * @param {number} pOrgUnitId OrgUnit for which to remove default layout from the current app/dataobject/register
     * @deprecated
     */
    async deleteDefaultLayout(pOrgUnitId: number) {
        try {
            await this._manageProc.execute({
                Action: 'delete_default',
                DataObject_ID: this.dataObjectId,
                App_ID: this._appId,
                OrgUnit_ID: pOrgUnitId,
                Register_ID: configurableRegister.id
            });
        } catch (ex) {
            throw this._wrapLayoutException(ex);
        }
    }

    /**
     * Set layout as the new default. A copy of this layout will be created, previous default layout will be deleted.
     * User context must have capability for setting default layouts.
     */
    async setLayoutAsDefault(pLayoutId: number) {
        try {
            const data = await this._manageProc.execute({
                Action: 'set_mew_default',
                DataObject_ID: this._dataObjectId,
                App_ID: this._appId,
                Register_ID: configurableRegister.id,
                Layout_ID: pLayoutId
            });
            if (data.Table[0]) {
                this.applyLayout(data.Table[0]);
            }
        } catch (ex) {
            throw this._wrapLayoutException(ex);
        }
    }

    /**
     * Set layout as default for OrgUnit. The new layout must be already shared to the same OrgUnit and cannot already be default
     * @param {number} pOrgUnitId OrgUnit for which new layout should be default
     * @param {number} pLayoutId New default layout id
     * @deprecated
     */
    async updateDefaultLayout(pOrgUnitId: number, pLayoutId: number) {
        try {
            await this._manageProc.execute({
                Action: 'set_default',
                DataObject_ID: this.dataObjectId,
                App_ID: this._appId,
                OrgUnit_ID: pOrgUnitId,
                Register_ID: configurableRegister.id,
                Layout_ID: pLayoutId
            });
        } catch (ex) {
            throw this._wrapLayoutException(ex);
        }
    }

    /**
     * Share a layout to an Org Unit
     * @param {Partial<ILayoutRecord>} pRecord The layout record to share
     */
    async shareLayout(pRecord: Partial<ILayoutRecord>) {
        try {
            if (!pRecord.Name) {
                throw new Error('Shared layout name can not be empty');
            } else if (pRecord.ID == null) {
                throw new Error('No layout ID provided to share');
            } else if (pRecord.OrgUnit_ID == null) {
                throw new Error('No Org Unit set for sharing the layout')
            }

            await this._manageProc.execute({
                Action: 'share',
                App_ID: this._appId,
                DataObject_ID: this.dataObjectId,
                Layout_ID: pRecord.ID,
                Name: pRecord.Name,
                Description: pRecord.Description,
                OrgUnit_ID: pRecord.OrgUnit_ID,
                Register_ID: pRecord.Register_ID,
                Default: pRecord.Default
            });

        } catch (ex) {
            throw this._wrapLayoutException(ex);
        }
    }

    /**Remove shared with me (persons) layout */
    unshareLayout(pId: number) {
        return this._manageProc.execute({
            Action: 'unshare',
            App_ID: this._appId,
            Register_ID: configurableRegister.id,
            DataObject_ID: this._dataObjectId,
            Person_ID: userSession.personId,
            Layout_ID: pId
        });
    }

    /** Update options for a registered layout module */
    updateModuleOptions(pModuleKey: string, pNewOptions: any) {
        this._registeredModules[pModuleKey] = pNewOptions;
    }

    async canSetLayoutAsDefault() {
        const instance = await import('./DataObject.Layout.UpdatesRetriever.ts').then(capChecker => {
            return capChecker.SharingCapabilitiesChecker.getInstance();
        });
        const canSet = await instance.canSetDefaultLayouts();
        return canSet;
    }

    clearDefaultLayout() {
        this._layoutStore?.removeDefaultLayout();
    }

    /** Get current stored layout changes (unsaved in db but saved to local storage) */
    getActiveLayoutStoredChanges(pModule?: string) {
        const layout = this._layoutStore?.getStoredChanges();
        const extractModule = () => {
            if (layout == null || pModule == null) { return layout; }
            try {
                const parsedLayout = JSON.parse(layout.Layout);
                return parsedLayout[pModule];
            } catch {
                return undefined;
            }
        }
        if (layout == null) {
            return undefined;
        } else if (this._activeLayout == null) {
            return extractModule();
        } else if (this._activeLayout?.id != layout.ID) {
            this._layoutStore?.deleteLayoutChanges();
            return undefined;
        } else {
            return extractModule();
        }
    }

    /** Stop async operations and discard this LayoutManager instance */
    destroy() {
        if (this._destroyed) { return; }
        this._destroyed = true;
    }

    private async _confirmLayoutUnlock(pId?: number) {
        const isLocked = this._layoutStore?.getIsLocked(pId);
        if (isLocked) {
            const { default: confirm } = await import('o365.controls.confirm.ts');
            try {
                await confirm({
                    title: $t('Locked layout'),
                    message: $t('Layout to be applied is locked, do you want to unlock it after applying it'),
                    btnTextOk: $t('Unlock it'),
                    btnTextCancel: $t('Keep it locked'),
                    backdrop: true
                });
                this._lockLayout = false;
                this._layoutStore!.setIsLocked(pId, false);
            } catch (ex) {
                this._lockLayout = true;
            }
        } else {
            this._lockLayout = false;
        }
    }


    /** Get the reactive data object */
    private _getDataObject() {
        if (this._destroyed) { return null; }
        if (this._constructed) {
            return getDataObjectById(this._dataObjectId, this._isSite ? 'site' : this._appId) as DataObject<T>;
        } else {
            return this._rawDataObject;
        }
    }

    /** Get reactive this */
    private _getThisRef() {
        return this._getDataObject()?.layoutManager;
    }

    /** Construct empty layout object with registered modules */
    private _getEmptyLayoutObject(pRecord?: {
        Default?: boolean
    }) {
        this._inEditMode = false;
        const modules: Record<string, LayoutModule<T>> = {};
        Object.keys(this._registeredModules).forEach(key => {
            modules[key] = new this._registeredModules[key](this._getModuleOptions(key), undefined, this._registeredModulesOptions[key]);
        });
        const layoutObject = new LayoutObject<T>(modules, {
            App_ID: this._appId,
            Register_ID: configurableRegister.id,
            Person_ID: userSession.personId,
            Name: userSession.name,
            Default: pRecord?.Default ?? true
        });
        return layoutObject;
    }

    /** Construct layout object from ILayoutRecord object */
    private _createLayoutObjectFromRecord(record: ILayoutRecord) {
        this._inEditMode = false;
        let parsedLayout: Record<string, any>;
        try {
            parsedLayout = JSON.parse(record.Layout);
        } catch (ex) {
            console.warn(ex);
            parsedLayout = {};
        }

        if (parsedLayout.Updated) {
            parsedLayout.Updated = parsedLayout.Updated.split('.')[0];
        }

        let parentLayout: ILayoutRecord | null = null;
        if (record.OrgUnit_ID != null && record.Person_ID != null) {
            parentLayout = this._layoutStore?.getDefaultContextLayout(record.OrgUnit_ID) ?? null;
        }

        const modules: Record<string, LayoutModule<T>> = {};

        Object.keys(this._registeredModules).forEach(key => {
            let parentValue: any = null;
            if (parentLayout) {
                parentValue = JSON.parse(parentLayout.Layout)[key];
            }
            modules[key] = new this._registeredModules[key](this._getModuleOptions(key), parsedLayout[key], this._registeredModulesOptions[key], parentValue);
        });
        const layoutObject = new LayoutObject<T>(modules, record, parentLayout ? JSON.parse(parentLayout.Layout) : undefined);
        if ((record.OrgUnit_ID != null && !record.Default) || (record.Person_ID != null && record.Person_ID !== userSession.personId)) {
            this._pauseSaving = true;
        }
        return layoutObject;
    }


      /** Get the default options for layout modules */
    private _getModuleOptions(pModuleKey: string): ILayoutModuleOptions<T> {
        const getControl = (pKey: string) => this._controls[pKey];
        const setControl = (pKey: string, pValue: any) => this._controls[pKey] = pValue;
        return {
            getDataObject: () => this._getDataObject()!,
            canSave: () => this.canSave,
            updateModuleOptions: (pNewOptions: any) => this.updateModuleOptions(pModuleKey, pNewOptions),
            getControl, setControl
            // getControl: (pKey: string) => this._activeLayout?._getControl(pKey, layoutsModuleSymbol),
            // setControl: (pKey: string, pControl: any) => this._activeLayout?._setControl(pKey, pControl, layoutsModuleSymbol)
        };
    }

    private _controls: Record<string, any> = {};

    /** Validate and retrieve new layouts in local store from the database */
    private async _validateLayouts() {
        try {
            const { default: LayoutChecker } = await import('./DataObject.Layout.UpdatesRetriever.ts');
            const layoutChecker = LayoutChecker.getInstance();
            const appLayouts = await layoutChecker.getAppLayouts(this._appId);
            const layoutsToFetch: number[] = [];
            let layoutsMap = appLayouts?.[this.dataObjectId] ?? {};
            Object.keys(layoutsMap).forEach(key => {
                const layout = layoutsMap[key as any];
                if (layout.Person_ID == userSession.personId && layout.OrgUnit_ID && layout.Default) {
                    delete layoutsMap[key as any];
                }
            });

            const invalidateLayout = (pLayout: ILayoutRecord) => {
                layoutsToFetch.push(pLayout.ID);
                if (this._activeLayout && pLayout.ID == this._activeLayout.id) {
                    const that = this._getThisRef();
                    // @ts-ignore (Force reactivity changes to work)
                    that._activeLayoutOutdated = true;
                }
            };

            let activeLayoutExists = false;
            let checkForDefault = false;
            const defaultLayout = this._layoutStore?.getDefaultLayout();
            if (defaultLayout) {
                const defaultLayoutFromDB = layoutsMap[defaultLayout.ID];
                if (defaultLayoutFromDB == null) {
                    this._layoutStore?.deleteLayout(defaultLayout);
                    activeLayoutExists = false;
                    checkForDefault = true;
                } else if (defaultLayout.Updated != defaultLayoutFromDB.Updated) {
                    activeLayoutExists = true;
                    invalidateLayout(defaultLayout);
                } else {
                    activeLayoutExists = true;
                }
            } else {
                checkForDefault = true;
            }

            const layoutSetByUser = this._layoutStore?.getUserSetLayout();
            if (layoutSetByUser) {
                const layoutSetByUserFromDB = layoutsMap[layoutSetByUser.ID];
                if (layoutSetByUserFromDB == null) {
                    this._layoutStore?.deleteLayout(layoutSetByUser);
                    activeLayoutExists = false;
                } else if (layoutSetByUser.Updated != layoutSetByUserFromDB.Updated) {
                    activeLayoutExists = true;
                    invalidateLayout(layoutSetByUser);
                } else {
                    activeLayoutExists = true;
                }
            }

            if (checkForDefault) {
                const defaultLayout = Object.values(layoutsMap).find((layout) => {
                    return !!layout.Default
                });
                if (defaultLayout) {
                    layoutsToFetch.push(defaultLayout.ID);
                }
            }

            if (layoutsToFetch.length) {
                const idsToFetch = layoutsToFetch.map(x => [x]);
                const layouts = await layoutChecker.getLayoutByIds(idsToFetch, this._appId);
                layouts.forEach(record => {
                    this._layoutStore?.storeLayout(record);
                });
            }

            if (!activeLayoutExists) {
                const defaultLayout = this._layoutStore?.getDefaultLayout();
                if (defaultLayout) {
                    this._activeLayout = this._createLayoutObjectFromRecord(defaultLayout);
                }
            }

            const that = this._getThisRef();
            // @ts-ignore (Force vue tracking)
            that._layoutsValidated = true;

        } catch (ex) {
            const layoutError = this._wrapLayoutException(ex);
            throw layoutError;
        } finally {
            if (this._activeLayoutOutdated) {
                this.reapplyLayout(this._activeLayout != null);
            }
        }
    }

    /** Wrap an error with LayoutError object */
    private _wrapLayoutException(ex: unknown) {
        if (ex instanceof LayoutError) {
            return ex;
        } else {
            // TODO: Proper unkown handling
            // @ts-ignore
            return new LayoutError(ex.message, this);
        }
    }

    //====================================
    // COMPATABILITY WITH PREVIOUS CONTROL
    //====================================
    // @ts-ignore
    private initDataGrid(options) {
        const gridControl = options.dataGridControl;
        const getColId = (field: any): string => field.colId ?? field.column ?? field.field;
        const baselineColumns: Record<string, ILayoutColumnProperties> = {};
        options.initialColumns.forEach((col: ILayoutColumnProperties & {
            colId: string,
            field: string,
            width?: string,
            column: string
        }, index: number) => {
            const colId = getColId(col);
            baselineColumns[colId] = {
                order: index + 1,
                // order: index + 2,
                width: col.width ? parseInt(col.width) : undefined,
                hide: col.hide ?? false,
                pinned: col.pinned ?? null,
                hideFromChooser: col.hideFromChooser
            };
        });
        this.registerModule('columns', LayoutColumnsModule, {
            dataGridControl: gridControl,
            baseline: baselineColumns
        })
    }
    // @ts-ignore
    private trackColumnChange() {
        this.activeLayout.modules.columns?.trackChanges();
    }
}

/**
 * An object containing all of the registered modules for a layout and the current layout info.
 * Each data object will only have a single LayoutObject instance.
 */
class LayoutObject<IT extends ItemModel = ItemModel> {
    /** Record info for the layout */
    private _record: Partial<ILayoutRecord>;
    /** Map of registered modules instances */
    private _layout: Record<string, LayoutModule<IT>> = {};
    /** Parent layout values used for merging baselines */
    private _parentLayout?: Record<string, any>;

    /**
     * A map of controls that can be added to the layout and shared between diffrent layout modules. 
     * For example gird columns module will add the dataGridControl here.
     */
    private _controls: Record<string, any> = {};
    /** Layout in the object form */
    get layout(): Record<string, any> {
        const layoutCopy: Record<string, any> = {};
        Object.keys(this._layout).forEach(key => {
            const value = this._layout[key].getValue();
            if (value !== undefined) {
                layoutCopy[key] = value;
            }
        });
        return layoutCopy;
    }
    /** Layout in object form for saving */
    get layoutToSave(): Record<string, any> {
        const layoutCopy: Record<string, any> = {};
        Object.keys(this._layout).forEach(key => {
            const value = this._layout[key].getValueForSave();
            if (value !== undefined) {
                layoutCopy[key] = value;
            }
        });
        return layoutCopy;
    }

    /** Parsed layout from the record */
    get layoutRecord(): Record<string, any> {
        try {
            return JSON.parse(this._record.Layout ?? '{}');
        } catch (ex) {
            return {};
        }
    }

    get layoutJSON() {
        return this._record.Layout;
    }

    /** Map of registed modules instances */
    get modules() {
        return this._layout;
    }

    /** Registered modules in array form */
    get modulesArray() {
        return Object.values(this._layout);
    }

    /** Parent layout values */
    get parentLayout() {
        return this._parentLayout;
    }

    /** Layout ID */
    get id() { return this._record.ID; }
    set id(value) { this._record.ID = value; }
    /** Layout Name */
    get name() { return this._record.Name; }
    set name(value) { this._record.Name = value; }
    /** Layout Description */
    get description() { return this._record.Description; }
    set description(value) { this._record.Description = value; }
    /** Layout owner's Person_ID. Is null for shared layouts */
    get personId() { return this._record.Person_ID; }
    /** Layout OrgUnit_ID. Is null for personal layouts */
    get contextId() { return this._record.OrgUnit_ID; }
    /** Layout Register_ID. Is applied only for configurable registers */
    get registerId() { return this._record.Register_ID; }
    /** Last stored update */
    get updated() { return this._record.Updated; }
    set updated(value) { this._record.Updated = value; }
    /** Indicates that the layout is default */
    get isDefault() { return !!this._record.Default; }
    /** Indicates that the layout doesn't have an ID */
    get isNew() { return !this._record.ID; }
    /** Returns the layout type based on the record values */
    get layoutType() {
        if (this.id) {
            const personId = userSession.personId;
            if (this.isDefault && this.personId === personId && this.contextId == null) {
                return LayoutType.PersonalDefault;
            } else if (this.isDefault && this.personId == null && this.contextId != null) {
                return LayoutType.SharedDefault;
            } else if (this.personId == personId && this.contextId != null) {
                return LayoutType.SharedPersonalDefault;
            } else if (!this.isDefault && this.personId == personId && this.contextId == null) {
                return LayoutType.Personal;
            } else if (!this.isDefault && this.personId == null && this.contextId != null) {
                return LayoutType.Shared;
            } else if (!this.isDefault && this.personId && this.personId != personId && this.contextId == null) {
                return LayoutType.SharedFromPerson;
            } else {
                return LayoutType.Unkown;
            }
        } else {
            return LayoutType.New;
        }
    }
    /** The record object of currently applied layout */
    get record() { return this._record; }

    get canAutoSave() {
        switch (this.layoutType) {
            case LayoutType.New:
            case LayoutType.PersonalDefault:
                return false;
            // return true;
            default:
                return false;
        }
    }

    /**
     * Create new LayoutObject
     * @param {Record<string,  LayoutModule>} pModules Map of module instances for this layout object
     * @param {Partial<ILayoutRecord>} pLayoutRecord Layout record info partial
     * @param {Record<string, any>} pParentLayout Parent layout values
     */
    constructor(pModules: Record<string, LayoutModule<IT>>, pLayoutRecord: Partial<ILayoutRecord> = {}, pParentLayout?: Record<string, any>) {
        this._layout = pModules;
        this._record = pLayoutRecord;
        this._parentLayout = pParentLayout;
    }

    /** Returns true if any of the modules has changes */
    hasChanges() {
        return Object.values(this._layout).some(layoutModule => layoutModule.hasChanges());
    }

    /** Called when a layout module is registered on the layout manager */
    appendModule<T extends LayoutModule<IT>>(pLayoutModule: T) {
        this._layout[pLayoutModule.key] = pLayoutModule;
    }

    /** Update the record for saving this layout as new */
    setAsNew() {
        switch (this.layoutType) {
            case LayoutType.PersonalDefault:
                this._record.Default = false;
                this._record.Name = undefined;
                break;
            case LayoutType.Shared:
                this._record.OrgUnit_ID = undefined;
                this._record.Person_ID = userSession.personId;
                this._record.Default = false;
                this._record.Name = undefined;
                break;
            case LayoutType.SharedDefault:
                this._record.OrgUnit_ID = undefined;
                this._record.Person_ID = userSession.personId;
                this._record.Default = false;
                this._record.Name = undefined;

                // this._record.Person_ID = userSession.personId;
                // this._record.Default = true
                // this._record.Name = this._record.Name + ` (${userSession.name})`;
                // this._record.Description = undefined;
                break;
            case LayoutType.SharedPersonalDefault:
                this._record.OrgUnit_ID = undefined;
                this._record.Default = false;
                this._record.Name = undefined;
                break;
            case LayoutType.New:
                this._record.Default = undefined;
                break;
            case LayoutType.Unkown:
                this._record.OrgUnit_ID = undefined;
                this._record.Person_ID = userSession.personId;
                this._record.Default = false;
                this._record.Name = undefined;
                break;
        }
        this._record.ID = undefined;
    }


    /**
     * Used by the layout manager to update the layout object record. Needs symbol for execution verification
     * @ignore
     */
    _updateRecord(pLayoutRecord: Partial<ILayoutRecord> | null, pSymbolCheck: Symbol) {
        if (pSymbolCheck !== layoutsModuleSymbol) { throw new Error('Only the layout manager can update the record'); }

        this._record = pLayoutRecord ?? {};
    }

    /**
     * Used by the layout manager to remove a layout module. Needs symbol for execution verification
     * @ignore
     */
    _removeModule(pKey: string, pSymbolCheck: Symbol) {
        if (pSymbolCheck !== layoutsModuleSymbol) { throw new Error('Only the layout manager can remove modules'); }

        delete this._layout[pKey];
    }

    /**
     * Used by the layout manager to share controls between a layout object  modules. Needs symbol for execution verification
     * @ignore
     */
    _getControl(pKey: string, pSymbolCheck: Symbol) {
        if (pSymbolCheck !== layoutsModuleSymbol) { throw new Error('Only the layout manager can remove modules'); }
        return this._controls[pKey];
    }

    /**
     * Used by the layout manager to share controls between a layout object  modules. Needs symbol for execution verification
     * @ignore
     */
    _setControl(pKey: string, pControl: any, pSymbolCheck: Symbol) {
        if (pSymbolCheck !== layoutsModuleSymbol) { throw new Error('Only the layout manager can remove modules'); }
        this._controls[pKey] = pControl;
    }

}

interface ILayoutSharedControls {
    dataGridControl?: DataGridControl
}

type MappedFunction<T, K extends keyof T = keyof T> = (key: K) => T[K];

/** Options type shared between all LayoutModule classes */
interface ILayoutModuleOptions<T extends ItemModel = ItemModel> {
    getDataObject: () => DataObject<T>;
    canSave: () => boolean;
    updateModuleOptions: typeof LayoutManager.prototype.updateModuleOptions
    getControl: MappedFunction<ILayoutSharedControls>;
    setControl: (pControlKey: string, pControl: any) => void;
};

// Define a type for the class constructor
type LayoutModuleConstructor<IT extends ItemModel = ItemModel, T extends LayoutModule<IT> = LayoutModule<IT>> = new (pOptions: ILayoutModuleOptions<IT>, pInitialValue?: any, pModuleOptions?: any, pDependencyValue?: any, pStoredChanges?: any) => T;

/** Abstract class that must be extended by every layout module */
export abstract class LayoutModule<IT extends ItemModel = ItemModel, T extends any = any> {
    /** The baseline value of the layout module */
    protected _baseline?: T;
    private __value?: T;
    /** The current applied value of the layout module */
    protected get _value() {
        return this.__value;
    }
    protected set _value(value) {
        if (value == null) {
            this.__value = value;
        } else {
            this.__value = JSON.parse(JSON.stringify(value));
        }
    }

    /** Get current instance of the data object */
    protected getDataObject: ILayoutModuleOptions<IT>['getDataObject']
    /** Returns true if the current layout allows saving */
    protected canSave: () => boolean;
    /** Get a control shared by another layout module */
    protected getSharedControl: ILayoutModuleOptions<IT>['getControl'];
    /** Share a control to be used in other layout modules */
    protected setSharedControl: ILayoutModuleOptions<IT>['setControl'];
    /** Identifier for the layout module */
    key: string;
    /** When set to true will be included when saving by default, otherwise will only be included when specified in save optinos */
    includeByDefault: boolean = false;
    /** Optional propmise that will be awaited before loading the dataobject on layout apply */
    beforeLoadPromise?: Promise<void>;

    constructor(pKey: string, pOptions: ILayoutModuleOptions<IT>) {
        this.key = pKey;
        this.getDataObject = pOptions.getDataObject;
        this.canSave = pOptions.canSave;
        this.getSharedControl = pOptions.getControl;
        this.setSharedControl = pOptions.setControl;
    }

    /** Apply the layout module. Called by layout object when applying  */
    /**
     * Apply the layout module. Called by layout object when applying.
     * @param pValue Layout module value that will be applied
     * @param pSkipValueSet Apply the value but don't set it in the module.
     */
    abstract apply(pVvalue: any, pSkipValueSet?: boolean): void;
    /** Get the currently applied value of the layout module */
    abstract getValue(): any;
    /** Get the value for the layout module when saving */
    abstract getValueForSave(): any;
    /** Called when saving to check if there are changes in the layout. If all moduels return false the save will be skipped */
    abstract hasChanges(): boolean;
    /** 
     * Get merged layout values with overrides
     * @param {any} pLayout Base value
     * @param {any} pOverrides Overrides value
     */
    abstract mergeValues(pLayout: any, pOverrides: any): any;
    /**
     * Called after applying or resetting a layout on all registered modules. 
     * If any of the moduels returns true will reload the data object after apllying/resetting the layout.
     */
    shouldLoadDataObject() { return false; };
    /**
     * Reset the value of this module to the baseline (developer set value) 
     */
    abstract reset(): void;
    /**
     * Get display name for the module. Used in UI.
     */
    getDisplayName() {
        return `${this.key.charAt(0).toUpperCase()}${this.key.slice(1)}`;
    }

    /** Optional helper method to track changes on a module */
    trackChanges() {
        throw new Error('Method not implemented');
    }

    /**
     * Called when updating the module options on the layout manager.  
     */
    onUpdateModuleOptions(pCurrentOptions: any, _pNewOptions: any) {
        console.warn(`${this.key} layout module does not have onModuleOptionsUpdated function implemented`);
        return pCurrentOptions;
    }

    /** Used by layouts manager to update the saved values */
    updateValue(value: T) {
        this.__value = value;
    }
}

/**
 * Layout module responsible for handling DataObject filters
 */
// @ts-ignore
class LayoutFilterModule<T extends ItemModel = ItemModel> extends LayoutModule<T, string | null | undefined> {
    private _shouldReload: boolean = false;

    constructor(pOptions: ILayoutModuleOptions<T>, pInitialValue?: string) {
        super('filter', pOptions);
        this._value = pInitialValue;
        this._baseline = this.getDataObject().recordSource.filterString;
        if (this._value != null) {
            // this.getDataObject().recordSource.filterString = this._value;
            this.getDataObject().filterObject.applyInitFilter(this._value, false);
            this.getDataObject().recordSource.filterString = this._value;

            Object.values((this.getDataObject().filterObject as any).filterItems as Record<string, FilterItem>).forEach(item => {
                if (item.selectedValue != null) {
                    item.setAsApplied();
                } else if (item.selectedValue === undefined) {
                    item.resetItem();
                }
            });

            this.getDataObject().filterObject.activeFilterObject = this.getDataObject().filterObject.filterObject;
        }
    }

    apply(value?: string | null, pSkipValueSet = false) {
        if (!pSkipValueSet) {
            this._value = value;
        }
        if (value !== undefined) {
            const dataObject = this.getDataObject();
            if (dataObject.filterObject.appliedFilterString !== value) {
                this._shouldReload = true;
                this.beforeLoadPromise = dataObject.filterObject.applyInitFilter(value ?? '', false);
            }
        }
    }

    getValue() {
        return this._value;
    }

    getValueForSave() {
        const appliedFilterString = this.getDataObject().filterObject.appliedFilterString;
        return appliedFilterString == this._baseline ? undefined : appliedFilterString;
    }

    hasChanges() {
        const currentFilterString = this.getDataObject().filterObject.appliedFilterString;
        return currentFilterString != this._value;
    }

    mergeOverrides(value: string | null) {
        if (this._value === undefined) {
            this._value = value;
        }
    }

    reset() {
        const dataObject = this.getDataObject();
        if (dataObject.filterObject.appliedFilterString != this._baseline) {
            this._shouldReload = true;
            dataObject.filterObject.applyInitFilter(this._baseline ?? '', false);
        }
        this._value = undefined;
    }

    shouldLoadDataObject() {
        const shouldReload = this._shouldReload;
        this._shouldReload = false;
        return shouldReload;
    }
}

/**
 * Layout module responsible for handling DataObject sort by
 */
// @ts-ignore
class LayoutSortByModule<IT extends ItemModel = ItemModel> extends LayoutModule<IT, Record<string, 'asc' | 'desc'>[] | null> {
    static getSortOrderForDataObject<T extends ItemModel = ItemModel>(pDataObject: DataObject<T>): Record<string, 'asc' | 'desc'>[] | null {
        const sortByObj = pDataObject.fields.fields
            .filter(field => field.sortOrder != null && field.sortDirection != null)
            .sort((a, b) => a.sortOrder! - b.sortOrder!)
            .map(field => {
                const result: Record<string, 'asc' | 'desc'> = {};
                result[field.field] = field.sortDirection ?? 'asc';
                return result;
            });
        return sortByObj.length > 0 ? sortByObj : null;
    }

    constructor(pOptions: ILayoutModuleOptions<IT>, pInitialValue: any, pBaseLineValue: any) {
        super('sortBy', pOptions);

        this._baseline = pBaseLineValue;
        this._value = pInitialValue;
        if (this._value != null) {
            this.getDataObject().recordSource.setSortOrder(this._value, true);
        }
    }

    apply(value: any, pSkipValueSet = false) {
        if (!pSkipValueSet) {
            this._value = value;
        }

        if (value) {
            this.getDataObject().recordSource.setSortOrder(value, true);
        }
    }

    getValue() {
        return this._value;
    }

    getValueForSave() {
        const dataObject = this.getDataObject();
        const currentSortBy = LayoutSortByModule.getSortOrderForDataObject(dataObject);
        if (objectsAreEqual(currentSortBy, this._baseline)) {
            return this._value ? null : undefined;
        } else {
            return currentSortBy;
        }
    }

    hasChanges() {
        const newValue = this.getValueForSave();
        if (newValue && this._value) {
            return !objectsAreEqual(newValue, this._value);
        } else {
            return newValue == null || this._value == null;
        }
    }

    mergeOverrides(_value: any) {

    }

    reset() {
        if (this._baseline) {
            this.getDataObject().recordSource.setSortOrder(this._baseline, false);
        }
        this._value = undefined;
    }

    shouldLoadDataObject() { return true; }
}

/**
 * Layout module responsible for handling DataObject group by
 * @deprecated
 */
export class LayoutGroupByModule {
    constructor() {
        throw new TypeError('Group By is deprecated, use node data')
    }
}

/**
 * Layout module responsible for handling grid columns
 */
class LayoutColumnsModule<IT extends ItemModel = ItemModel> extends LayoutModule<IT, Record<string, ILayoutColumnProperties> | undefined> {
    /** The data grid control for this module */
    private _gridControl: DataGridControl;
    private __valuesToSave: Record<string, ILayoutColumnProperties> | null = null;
    protected get _valuesToSave() {
        return this.__valuesToSave;
    }
    protected set _valuesToSave(value) {
        if (value == null) {
            this.__valuesToSave = value;
        } else {
            this.__valuesToSave = JSON.parse(JSON.stringify(value));
        }
    }

    constructor(pOptions: ILayoutModuleOptions<IT>, pInitialValue: Record<string, ILayoutColumnProperties> | undefined, pModuleOptions: {
        dataGridControl: DataGridControl,
        baseline: Record<string, ILayoutColumnProperties>
    }, pParentValue?: Record<string, ILayoutColumnProperties>, pStoredChanges?: Record<string, ILayoutColumnProperties>) {
        super('columns', pOptions);
        this._gridControl = pModuleOptions.dataGridControl;
        this.setSharedControl('dataGridControl', this._gridControl);
        if (pParentValue) {
            this._baseline = this.mergeValues(pModuleOptions.baseline, pParentValue);

        } else {
            this._baseline = pModuleOptions.baseline;
        }
        this._value = pInitialValue;
        if (pStoredChanges) {
            this.apply(pStoredChanges, true);
        } else if (this._value) {
            this.apply(this._value);
        }
    }

    apply(columns: Record<string, ILayoutColumnProperties>, pSkipValueSet = false) {
        if (!pSkipValueSet) {
            this._value = columns;
        }
        if (columns == null) {
            this._gridControl.dataColumns.resetColumnLayout();
            this._valuesToSave = columns;
            return;
        }
        const baselineColumns = this._baseline;
        const mergedLayout: Record<string, ILayoutColumnProperties> = {};

        //Loop through all exsisting columns in the data grid and merge baseline values with the layout values
        this._gridControl.dataColumns.resetColumnLayout();
        this._gridControl.dataColumns.columns.forEach(col => {
            if (baselineColumns == null || baselineColumns[col.colId] == null) { return; }
            const baselineColumn = baselineColumns[col.colId];
            const columnMerge: Partial<ILayoutColumnProperties> = {};

            if (baselineColumn.width !== col.width) {
                columnMerge.width = baselineColumn.width;
            }
            if ((baselineColumn.hide ?? false) != col.hide) {
                columnMerge.hide = baselineColumn.hide;
            }
            if (baselineColumn.pinned != col.pinned) {
                columnMerge.pinned = baselineColumn.pinned;
            }

            if (baselineColumn.order != col.order) {
                columnMerge.order = baselineColumn.order;
            }

            const columnToApply = columns[col.colId] ?? {};
            mergedLayout[col.colId] = { ...columnMerge, ...columnToApply };
            if (Object.keys(mergedLayout[col.colId]).length === 0) { delete mergedLayout[col.colId]; }
        });
        if (!pSkipValueSet) {
            this._value = columns;
        }
        this._valuesToSave = columns;
        this._gridControl.applyLayout(mergedLayout);
    }

    getValue() {
        return this._value;
    }

    getValueForSave() {
        let layout: Record<string, ILayoutColumnProperties> | undefined = undefined;
        if (this._valuesToSave && Object.keys(this._valuesToSave).length > 0) {
            const layoutColumns: Record<string, ILayoutColumnProperties> = {};
            Object.keys(this._valuesToSave).forEach(key => {
                const properties: ILayoutColumnProperties = {};
                const column = this._valuesToSave?.[key];
                const baseline = this._baseline?.[key];
                if (!baseline) { return; }
                if (column?.width !== undefined && baseline.width !== column.width) {
                    properties.width = column.width;
                }
                if (column?.hide !== undefined && baseline.hide !== column.hide) {
                    properties.hide = column.hide;
                }
                if (column?.pinned !== undefined && baseline.pinned !== column.pinned) {
                    properties.pinned = column.pinned;
                }
                // if (column?.order !== undefined && baseline.order !== column.order) {
                //     properties.order = column.order;
                // }
                if (column?.order !== undefined) {
                    properties.order = column.order;
                }
                if (column?.hideFromChooser !== undefined && baseline.hideFromChooser !== column.hideFromChooser) {
                    properties.hideFromChooser = column.hideFromChooser;
                }

                if (Object.keys(properties).length > 0) {
                    layoutColumns[key] = properties;
                }
            });
            if (Object.keys(layoutColumns).length > 0) {
                layout = layoutColumns;
            }
        }
        this._valuesToSave = layout ?? null;
        return layout;
    }

    hasChanges() {
        if ((this._value == null) != (this._valuesToSave == null)) {
            // Has changes, one of the values is null
            return true;
        } else if (this._value != null && this._valuesToSave != null) {
            // Both are not null, preform deep changes check
            return !objectsAreEqual(this._value, this._valuesToSave);
        } else {
            // Both are null, no changes
            return false;
        }
    }

    mergeValues(pBaseline: Record<string, ILayoutColumnProperties>, pOverrides: Record<string, ILayoutColumnProperties>) {
        if (pBaseline == null || pOverrides == null) {
            return pOverrides == null ? pBaseline : pOverrides;
        }
        const mergedLayout: Record<string, ILayoutColumnProperties> = {};
        const baselineKeys: string[] = [];
        Object.keys(pBaseline).forEach(key => {
            baselineKeys.push(key);
            mergedLayout[key] = pBaseline[key];
            const overrides = pOverrides[key];
            if (overrides == null) { return; }
            if (overrides.hide != null) {
                mergedLayout[key].hide = overrides.hide;
            }
            if (overrides.hideFromChooser != null) {
                mergedLayout[key].hideFromChooser = overrides.hideFromChooser;
            }
            if (overrides.order != null) {
                mergedLayout[key].order = overrides.order;
            }
            if (overrides.pinned != null) {
                mergedLayout[key].pinned = overrides.pinned;
            }
            if (overrides.width != null) {
                mergedLayout[key].width = overrides.width;
            }
        });
        Object.keys(pOverrides).filter(key => !baselineKeys.includes(key)).forEach(key => {
            mergedLayout[key] = pOverrides[key];
        });

        return mergedLayout;
    }

    reset() {
        this._value = undefined;
        this._valuesToSave = null;
        this._gridControl.dataColumns.resetColumnLayout();
        // this._gridControl.applyLayout(this._baseline)
    }

    /** Update layout values with the property changes from data columns */
    trackChanges() {
        // if (!this.canSave()) { return; }
        let hasOrderChanges = false;
        this._gridControl.dataColumns.columns.forEach(column => {
            if (column.propertyChanges && Object.keys(column.propertyChanges).length > 0) {
                const changes = column.propertyChanges;
                Object.entries(changes).forEach(([property, value]) => {
                    if (!['width', 'pinned', 'hide', 'hideFromChooser', 'order'].includes(property)) { return; }
                    if (this._valuesToSave == null) { this._valuesToSave = {}; }
                    if (this._valuesToSave[column.colId] == null) { this._valuesToSave[column.colId] = {}; }
                    // @ts-ignore
                    this._valuesToSave[column.colId][property] = value;
                    if (property === 'order') { hasOrderChanges = true; }
                });
            }
        });

        if (hasOrderChanges) {
            const baselineOrderCheck = (colId: string) => {
                if (this._baseline == null || this._valuesToSave == null || this._baseline[colId] == null) { return; }
                const baselineOrder = this._baseline[colId].order ?? 0;
                const column = this._valuesToSave[colId];
                if (column == null) { return; }
                const layoutOrder = column.order; // current order in the layout
                let lowerColumnCount = 0;
                let higherColumnCount = 0;
                Object.keys(this._valuesToSave).forEach(key => {
                    if (key === colId) { return; }
                    const newColumn = this._valuesToSave![key];
                    if (newColumn == null) { return; }
                    const order = newColumn.order;
                    if (order == null) { return; }
                    if ((this._baseline![colId]?.order ?? 0) >= baselineOrder) {
                        if (order <= baselineOrder) {
                            lowerColumnCount += 1;
                        }
                    } else if (order > baselineOrder) {
                        higherColumnCount += 1;
                    }
                });
                const baselineIncrement = lowerColumnCount - higherColumnCount;
                if (layoutOrder === baselineOrder + baselineIncrement) {
                    // column.order = undefined;
                    delete column.order;
                    return true;
                } else {
                    return false;
                }
            };

            // this._gridControl.dataColumns.columns.filter(col => !this._gridControl.isColumnSystem(col.colId)).forEach(col => {
            //     if (!this._valuesToSave || this._valuesToSave[col.colId]?.order != null) { return; }
            //     if (this._valuesToSave[col.colId] == null) { this._valuesToSave[col.colId] = {}; }
            //     this._valuesToSave[col.colId].order = col.order;
            // });

            Object.keys(this._valuesToSave!).forEach(key => {
                const column = this._valuesToSave![key];
                if (column.order == null) { return; }
                const dataColumn = this._gridControl.dataColumns.getColumn(key);
                if (dataColumn == null) { return; }
                if (dataColumn.order !== column.order) {
                    if (!baselineOrderCheck(key)) {
                        column.order = dataColumn.order;
                    }
                } else {
                    baselineOrderCheck(key);
                }
            });
        }
    }

    addColumnToBaseline(colId: string, definition: any) {
        if (!this._baseline) { this._baseline = {}; }

        Object.keys(this._baseline).forEach(key => {
            if (this._baseline?.[key] == null) { return; }
            const order = this._baseline[key].order;
            if (order != null && order >= definition.order) {
                // column and order are not null due to above checks, ts worker being weird here
                // @ts-ignore
                this._baseline[key].order += 1;
            }
        });

        this._baseline[colId] = definition;
    }

    removeColumnFromBaseline(colId: string) {
        if (!this._baseline || this._baseline[colId] == null) { return; }
        const order = this._baseline[colId].order;
        delete this._baseline[colId];
        Object.keys(this._baseline).forEach(key => {
            if (this._baseline?.[key] == null) { return; }
            const baselineOrder = this._baseline[key].order;
            if (order != null && baselineOrder != null && baselineOrder >= order) {
                // Multiple checks above ensure this is not null
                // @ts-ignore
                this._baseline[key].order -= 1;
            }
        });
    }

    addColumnToCurrent(pColId: string, pDefinition: any) {{
        if (this._valuesToSave == null) { this._valuesToSave = {}; }
        this._valuesToSave[pColId] = pDefinition;
    }}
}

/**
 * Model of O365_Layouts records
 */
interface ILayoutRecord {
    ID: number,
    Updated: string,
    Layout: string,
    Default: boolean,
    Person_ID?: number,
    OrgUnit_ID?: number,
    Register_ID?: number,
    DataObject_ID: string,
    App_ID: string,
    Name: string,
    Description?: string
}

/** Properties of columns that are stored in layouts */
interface ILayoutColumnProperties {
    pinned?: 'left' | 'right' | null,
    width?: number,
    order?: number,
    hide?: boolean,
    hideFromChooser?: boolean
}

/** Extended error class for exceptions that occur in layouts */
class LayoutError<T extends ItemModel = ItemModel> extends Error {
    dataObjectId: string;
    constructor(message: string, layoutManager: LayoutManager<T>) {
        const dataObjectId = layoutManager.dataObjectId;
        message = `${dataObjectId}.layoutManager: ${message}`;
        super(message);
        this.dataObjectId = dataObjectId;
    }
}

/** Enum of possible layout types */
enum LayoutType {
    /**
     * Personal default, can be auto applied.
     * [Person_ID] = currentPersonId AND [DEFAULT]
     */
    PersonalDefault = 'personalDefault',
    /**
     * Personal, not auto applied.
     * [Person_ID] = currentPersonId AND NOT [DEFAULT]
     */
    Personal = 'personal',
    /**
     * Shared default for a specific context, can be auto applied.
     * [OrgUnit_ID] = currentContextId AND [DEFAULT]
     */
    SharedDefault = 'contextDefault',
    /**
     * Personal overrides ontop of a shared default, can be auto applied.
     * [OrgUnit_ID] = currentContextId AND [Person_ID] = currentPersonId AND [DEFAULT]
     */
    SharedPersonalDefault = 'contextPersonal',
    /**
     * Shared, not auto applied.
     */
    Shared = 'shared',
    /**
     * Shared from person.
     */
    SharedFromPerson = 'sharedFromPerson',
    /** Layout without an ID. */
    New = 'new',
    /** Layout that is not adhering to the layout engine specifications. */
    Unkown = 'unkown'
};

function objectsAreEqual(obj1: any, obj2: any): boolean {
    try {
        if (obj1 == null || obj2 == null) {
            return obj1 == obj2;
        }
        return Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every(key => {
            if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                return objectsAreEqual(obj1[key], obj2[key]);
            } else {
                return obj1[key] === obj2[key];
            }
        });
    } catch (_ex) {
        return false;
    }
}

export { LayoutObject, LayoutError, LayoutType, ILayoutModuleOptions, ILayoutRecord, ILayoutColumnProperties, objectsAreEqual };